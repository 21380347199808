/* You can add global styles to this file, and also import other style files */
@import "/src/scss/variables.scss";
@import "/src/scss/breakpoints.scss";
@import "@ng-select/ng-select/themes/material.theme.css";
@import "ngx-multiple-dates/prebuilt-themes/indigo-pink.css";
@import "animate.css";
@import "/src/scss/mat-stepper.scss";
@import "/src/scss/mat-calendar.scss";
@import "/src/scss/form-field.scss";
@import "/src/scss/snack-bar.scss";
@import "/src/scss/applications-select.scss";
@import "/src/scss/guide.scss";
@import "/src/scss/refresh-token-modal.scss";
@import "/src/scss/pills.scss";
@import "/src/scss/support-chat.scss";
@import "/src/scss/ng-select";
@import "/src/scss/app-ant-switch.scss";
@import "/src/scss/popover.scss";
@import "/src/scss/nz-drawer.scss";
@import "/src/scss/nz-tooltip.scss";

html,
body {
  height: 100%;
  overflow: hidden;

  @include media("<=phone") {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  // background-color: $secondary;
  @include gradBg();
}

b {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: 500;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  border-width: 5px;
  @include fontSizeFactored(12px);

  @include media("<phone") {
    @include fontSizeFactored(16px);
  }
}

.standard-form-button {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid $grey_lighter;
  text-align: center;
  background-color: $white;
  cursor: pointer;
  transition: background-color ease 300ms;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;

  @include fontSizeFactored(12px);
  line-height: 14px;
  font-weight: 500;
  color: $grey_darkest;
  box-sizing: border-box;

  &:hover {
    background-color: $primary;
    color: $white;
    border-color: $primary;
  }

  &.button-primary {
    background-color: $primary;
    color: $white;
    border-color: $primary;

    &:hover {
      background-color: $secondary;
      border-color: $secondary;
    }
  }

  &.button-toggled {
    background-color: $primary;
    color: $white;
    border-color: $primary;
  }

  &.secondary-button {
    background-color: $secondary;
    color: $white;
    border-color: $secondary;

    &:hover {
      background-color: $primary;
      border-color: $primary;
    }
  }

  &.tertiary-button {
    background-color: $tertiary;
    color: $white;
    border-color: $tertiary;

    &:hover {
      background-color: $primary;
      border-color: $primary;
    }
  }

  &.negative-button {
    background-color: $white;
    color: $grey_darkest;
    border-color: $grey_lighter;

    &:hover {
      background-color: $negative;
      color: $white;
      border-color: $negative;
    }
  }

  &:disabled {
    background-color: $grey_lightest;
    color: $grey_lighter;
    border-color: $grey_lightest;
    cursor: unset;

    &:hover {
      background-color: $grey_lightest;
      color: $grey_lighter;
      border-color: $grey_lightest;
    }
  }
}

.process-documents-stepper {
  .mat-step-text-label {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .step-header-icons {
      margin-left: 25px;

      @include media("<desktop") {
        margin-left: 0;
      }
    }

    mat-icon {
      margin: 0 4vw;
    }
  }
}

.mat-flat-button {
  &.cdk-keyboard-focused {
    border: 3px solid $tertiary;
  }
}

.mat-raised-button {
  &.cdk-keyboard-focused {
    border: 3px solid $tertiary;
  }

  &.mat-button-green {
    background-color: $positive;
    color: #fff;
  }
}

.mat-form-field-type-ngx-multiple-dates {
  .mat-form-field-flex {
    align-items: center;
  }
}

.universalTooltip,
.personalDataFormTooltip {
  @include fontSizeFactored(10px);
  font-weight: 500;
  background-color: $primary;
  border-radius: 8px;
  color: $white;

  &.mat-tooltip {
    padding: 20px 21px;
    border-radius: 8px;
    max-width: 170px;
  }
}

.chatTooltip {
  @include fontSizeFactored(10px);
  font-weight: 500;
  background-color: $white;
  border-radius: 8px;
  color: $grey_dark;
  border: 1px solid $grey_lighter;

  &.mat-tooltip {
    padding: 5px 8px;
    color: $grey_dark;
    border-radius: 8px;
    max-width: 170px;
  }
}

// this helps keep heihgt of input as in designed BUT
// causes text to not be centered vertically
// so for now disabled
// .meansOfSubsistenceSavingsAmount-field,
// .applyingForExperience-field,
// .legalBasisOfStay-field,
// .lastEntryDate-field,
// .residenceVisaExpiryDate-field,
// .lastYearEmploymentDate-field {
//   .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
// padding: 1.1em 0 0.75em 0;
// }
//   .mat-form-field-label {
//     white-space: pre-line;
//   }
// }

.ng-dropdown-panel .ng-dropdown-panel-items {
  .ng-option {
    height: unset;
    min-height: unset;
    line-height: unset;
    padding: 20px 16px;
  }
}

.mat-select-panel mat-option.mat-option {
  height: unset;
  min-height: unset;
  line-height: unset;
}

.mat-option-text.mat-option-text {
  white-space-collapse: collapse;
  text-wrap: wrap;
  height: unset;
  min-height: unset;
  line-height: unset;
  padding: 20px 16px;

  @include media("<400px") {
    padding: 5px 4px;
  }
}

.residencePostalCode-field,
.residenceVoivodeshipName-field,
.residenceMunicipality-field,
.residenceDistrict-field,
.residenceCityListed-field {
  .mat-form-field {
    line-height: 1.425;
  }
}

.standard-select.mat-select-panel {
  margin-top: 35px !important;
  border-radius: 10px;

  &.legal-basis-select {
    .mat-option:not(.mat-option-multiple) {
      min-height: 50px;
      @include fontSizeFactored(12px);
      line-height: 18px;
      padding: 0;
    }
  }

  &.longer-select {
    max-height: 350px;

    .mat-option:not(.mat-option-multiple) {
      min-height: 50px;
      @include fontSizeFactored(12px);
      line-height: 18px;
      padding: 0;
    }
  }

  .mat-option.mat-selected:not(.mat-option-multiple) {
    background: $primary;

    .mat-option-text {
      color: $white;
      @include fontSizeFactored(12px);

      @include media("<phone") {
        @include fontSizeFactored(16px);
      }
    }
  }

  .mat-option:hover {
    background: $tertiary;

    .mat-option-text {
      color: $primary;
    }
  }

  &.chat-topic-select {
    .mat-option:not(.mat-selected) .mat-option-text {
      .red-me-up {
        color: $negative;
      }
    }
  }

  &.chat-topic-select {
    .mat-option .mat-option-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      line-height: 20px;
      margin: 15px 0;

      .new-messages-dot {
        width: 10px;
        height: 10px;
        background-color: $negative;
        border-radius: 10px;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 7px;
    border-radius: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
    margin: 5px 0;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 10px;
  }
}

.application-type-select.mat-select-panel {
  max-height: 330px;
  min-width: 50vw !important;
  width: 50vw !important;

  .mat-option-text {
    display: flex;
    justify-content: flex-start;
    line-height: 2em;
    align-items: center;
    text-overflow: ellipsis;
    padding: 8px 0;
  }
}

.mat-datepicker-content .mat-datepicker-content-container-with-custom-header .mat-calendar {
  height: 325px !important;
}

.mat-button .mat-button-focus-overlay {
  background-color: transparent;
}

.mat-badge-warn .mat-badge-content {
  background-color: $negative;
  color: #fff;
}

.mat-badge-accent .mat-badge-content {
  background-color: #fff;
  color: $grey;
  border: 1px solid $grey_lighter;
}

.link-without-style {
  color: inherit !important;
  text-decoration: unset;
  cursor: unset;
}

.assistant-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include fontSizeFactored(12px);
  color: $grey_dark;

  &.highlight {
    color: $primary_dark;
  }

  .user-name {
    min-width: 10vw;
  }

  .user-roleName,
  .user-telephoneNumber {
    min-width: 8vw;
    @include fontSizeFactored(10px);
  }
}

.mat-selected .assistant-option {
  color: $white;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

.mat-input-element[type="time"]::after {
  content: none !important;
}

.mat-menu-panel.main-user-menu {
  @include media("<=400px") {
    width: 100vw;
    min-width: unset;
    max-width: unset;
  }
  @include media(">=400px") {
    min-width: 300px;
    max-width: 350px;
  }

  .user-profile-menu-item,
  .back-office-menu-item {
    height: 60px;
  }

  .menu-item-office-city {
    position: absolute;
    font-size: 11px;
    color: $grey;
    top: 25px;
    left: 56px;

    &.missing-assigned-office {
      color: $negative;
      font-weight: 500;
    }
  }
}

.mode-changed-modal {
  .mat-dialog-content {
    max-height: 78vh;
  }
}

.instructions-personalization-modal {
  .mat-dialog-content {
    max-height: 75vh;
    min-height: 75vh;
  }
}

.mat-menu-panel.mobile-menu {
  width: 96vw;
  min-width: unset;
  min-height: unset;
  max-width: unset;
  max-height: unset;
}

.mat-menu-panel.mos-dropdown-menu,
.mat-menu-panel.inpol-dropdown-menu {
  min-width: 34vw;
  max-width: 350px;
  @include fontSizeFactored(12px);
  padding: 5px 0;
  background-color: $secondary;
  box-shadow: unset;

  @include media("<400px") {
    min-width: unset;
    max-width: unset;
  }

  @include media("<400px") {
    width: 80vw;
    border: 1px solid black;
  }

  button {
    width: 80%;
    min-height: 30px;
    @include fontSizeFactored(11px);
  }

  .own-credentials,
  .default-credentials {
    text-align: center;
    margin: 10px 0 5px 0;
  }

  .own-credentials button:not(.mat-button-disabled) {
    background-color: $primary;
    @include fontSizeFactored(14px);
    min-height: 45px;
    width: 90%;
  }

  .default-credentials {
    margin-top: 25px;

    button {
      @include fontSizeFactored(10px);
      height: 25px;
      min-height: 25px;
      line-height: 21px;
      width: 50%;
      color: $grey_lightest;
      border: 1px solid $action;
      background-color: $secondary;
      box-shadow: none;
    }
  }
}

.review-items-modal {
  .mat-dialog-content {
    max-height: 35vh;
    border-top: 1px solid $grey_lightest;
    border-bottom: 1px solid $grey_lightest;

    &::-webkit-scrollbar {
      width: 7px;
      border-radius: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
      margin: 5px 0;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
      border-radius: 10px;
    }
  }
}

.mat-tab-label {
  padding: 0 10px;
}

@include media("<400px") {
  .mat-select-value {
    @include fontSizeFactored(16px);
  }

  ::ng-deep .ng-select-container {
    height: 5px !important;
    font-size: 0.8em !important;
  }
}

.process-type-modal {
  .mat-dialog-content {
    padding: 0 16px;
    margin: 0 -22px;
  }
}

.non-clickable {
  pointer-events: none;
  cursor: default;
  color: $black;
  text-decoration: none;
  font-weight: unset !important;
}

@include media("<phone") {
  .mat-paginator-container {
    justify-content: center !important;
  }
}
